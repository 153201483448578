.marker-tooltip {
    border-radius: 15px;
}

.marker-tooltip-night {
    background-color: #212121;
    color: antiquewhite;
    border-radius: 15px;
    border-color: #212121;
}

.marker-tooltip-night::before {
    border-bottom: 7px solid #212121;
}

