body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiListItem-gutters {
    padding-left: 10px !important;
}

.MuiListItemIcon-root {
    min-width: 30px !important;
}

.MuiSvgIcon-fontSizeSmall {
    font-size: 18px !important;
}

@media (prefers-color-scheme: dark) {
    .gm-style .gm-style-iw-c {
        background-color: #5A5A55;
    }
}

@media (prefers-color-scheme: light) {
    .gm-style .gm-style-iw-c {
        background-color: dodgerblue;
    }
}


.map-night-design {
    filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

.leaflet-center {
    left: 50%;
    transform: translate(-50%, 0%);
    pointer-events: auto;
}

.mui-datagrid-no-border {
    border: 0 !important;
}

.mui-listener-card-header {
    padding: 4px !important;
}

li {
    background-image: none !important;
}

.rdrDefinedRangesWrapper {
    display: none !important;
}

.dot-label {
    font-size: 12px;
}

